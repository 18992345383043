import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setIsSideDrawerOpen } from '../../store/reducers/navigationReducer';
import { resetUserState } from '../../store/reducers/userReducer';
import WNButton from '../FormComponents/WNButton';
import FindABlogSearchComponent from '../SearchComponents/FindABlogSearchComponent';

const HomeHeadingComponent = (props: HeadingProps) => {
  const { headingText } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state: AppState) => state.user.isLoggedIn);

  const handleLoginClick = () => {
    navigate('/login-or-signup');
  };

  const handleLogoutClick = () => {
    dispatch(resetUserState());
    navigate('/home');
  };

  return (
    <div className="home-heading-wrapper">
      <MenuIcon
        className="hamburger-menu-wrapper"
        onClick={() => dispatch(setIsSideDrawerOpen(true))}
      />
      <h1 className="home-heading-text">{headingText}</h1>
      <FindABlogSearchComponent />
      {isLoggedIn ? (
        <WNButton
          buttonText="Logout"
          optionalClass="logout-button"
          onClick={handleLogoutClick}
        />
      ) : (
        <WNButton
          buttonText="Login"
          optionalClass="login-nav-button"
          onClick={handleLoginClick}
        />
      )}
    </div>
  );
};

export default HomeHeadingComponent;
