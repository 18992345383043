import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { sendGetRequest, sendPostRequest } from './requestUtil';

export const getAllUserInfoByEmail = createAsyncThunk(
  'get-all-user-info-by-email',
  async (email: string) => {
    return sendGetRequest(`/api/get-all-user-info-by-email/${email}/`);
  }
);

export const getUserByUsername = createAsyncThunk(
  'get-user-by-username',
  async (username: string) => {
    return sendGetRequest(`/api/validate-username/${username}`);
  }
);

export const loginUser = createAsyncThunk(
  'login-user',
  async (body: LoginRequestType) => {
    return sendPostRequest('/api/auth/token/login/', body);
  }
);

export const signUpUser = createAsyncThunk(
  'sign-up-user',
  async (body: SignUpRequestType, { rejectWithValue }) => {
    const url = '/api/auth/users/';
    const token = undefined;
    let headers: any = {};
    if (token) headers['Authorization'] = `Token ${token}`;

    try {
      let response = await axios
        .post(url, body, {
          headers: headers,
        })
        .then(res => res.data)
        .catch(error => Promise.reject(error));
      return response;
    } catch (error: any) {
      let prependMessage = 'Error: ';
      const data = error.response?.data;
      if (data && data instanceof Object) {
        const items = Object.values(data).flat();
        if (items instanceof Array) {
          return rejectWithValue(prependMessage + items[0]);
        }
      }

      rejectWithValue(`An error occured (${error.response.status})`);
    }
  }
);

export const uploadProfilePicture = createAsyncThunk(
  'upload-profile-picture',
  async (body: FormData, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/upload-profile-picture/', body, token);
  }
);

export const createUpdateProfile = createAsyncThunk(
  'create-profile',
  async (body: CreateProfileType, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/profile-create-update/', body, token);
  }
);

export const getCustomerInfo = createAsyncThunk(
  'get-customer-info',
  async (_, { getState }) => {
    const state = getState() as AppState;
    const token = state.user.authToken;
    return sendPostRequest('/api/stripe-customer-exists/', {}, token);
  }
);
