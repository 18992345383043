import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { searchBlogs } from '../../store/actions/searchActions';
import { setSearchQuery } from '../../store/reducers/searchReducer';
import { store } from '../../store/store';

const FindABlogSearchComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    dispatch(setSearchQuery(query));
    const queryParams = { query };
    await store.dispatch(searchBlogs(queryParams));
    navigate(`/search-results`);
  };

  return (
    <div className="find-a-blog-search-wrapper">
      <div className="search-wrapper">
        <SearchIcon onClick={handleSearch} />
        <input
          type="search"
          className="search-input"
          placeholder="Search the Wildernet"
          value={query}
          onChange={e => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          enterKeyHint="search"
        />
      </div>
    </div>
  );
};

export default FindABlogSearchComponent;
