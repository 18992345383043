import axios from 'axios';
import { useEffect, useState } from 'react';

interface ProgressBarProps {
  targetCount: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ targetCount }) => {
  const [totalBlogs, setTotalBlogs] = useState<number>(0);

  useEffect(() => {
    const fetchTotalBlogs = async () => {
      try {
        const response = await axios.get('api/total-blogs/');
        setTotalBlogs(response.data.total_blogs);
      } catch (error) {
        console.error('Error fetching total blogs count:', error);
      }
    };

    fetchTotalBlogs();
  }, []);

  const progress = Math.min((totalBlogs / targetCount) * 100, 100);

  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div
          className={`progress-bar-fill ${progress >= 100 ? 'completed' : ''}`}
          style={{ width: `${progress}%` }}
        />
      </div>
      <p className="progress-text">
        {totalBlogs} / {targetCount} Websites Uploaded
      </p>
    </div>
  );
};

export default ProgressBar;
